<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <div class="search_wrap">
          <span class="option_txt">조회기간</span>
          <div class="input_text">
            <input
              type="date"
              placeholder="YYYY-MM-DD"
              :value="start_date"
              @change="
                setStartDate($event);
                key++;
              "
            />
          </div>
          <p>~</p>
          <div class="input_text">
            <input
              type="date"
              placeholder="YYYY-MM-DD"
              :value="end_date"
              @change="
                setEndDate($event);
                key++;
              "
            />
          </div>
          <div class="input_text">
            <input
              type="text"
              :value="
                `발효완료 PH값 : ${
                  plc_sensors.find(x => x.id == 5)
                    ? plc_sensors.find(x => x.id == 5).min_value
                    : ' - '
                }이하`
              "
              disabled
            />
          </div>
          <!-- <button class="excel_btn" @click="exportExcel()">
            엑셀로 저장
          </button> -->
        </div>
        <div class="tank_check_wrap">
          <div class="input_checkbox">
            <label
              for="checkbox1"
              class="chk_box"
              :class="tank_filter.tank1 ? 'active' : ''"
            >
              <i class="fa fa-check"></i>
            </label>
            <input
              type="checkbox"
              id="checkbox1"
              @click="
                tank_filter.tank1 = !tank_filter.tank1;
                key++;
              "
            />
            <label for="checkbox1" class="label_txt">발효탱크#1</label>
          </div>
          <div class="input_checkbox">
            <label
              for="checkbox2"
              class="chk_box"
              :class="tank_filter.tank2 ? 'active' : ''"
            >
              <i class="fa fa-check"></i>
            </label>
            <input
              type="checkbox"
              id="checkbox2"
              @click="
                tank_filter.tank2 = !tank_filter.tank2;
                key++;
              "
            />
            <label for="checkbox2" class="label_txt">발효탱크#2</label>
          </div>
          <div class="input_checkbox">
            <label
              for="checkbox3"
              class="chk_box"
              :class="tank_filter.tank3 ? 'active' : ''"
            >
              <i class="fa fa-check"></i>
            </label>
            <input
              type="checkbox"
              id="checkbox3"
              @click="
                tank_filter.tank3 = !tank_filter.tank3;
                key++;
              "
            />
            <label for="checkbox3" class="label_txt">발효탱크#3</label>
          </div>
          <div class="input_checkbox">
            <label
              for="checkbox4"
              class="chk_box"
              :class="tank_filter.tank4 ? 'active' : ''"
            >
              <i class="fa fa-check"></i>
            </label>
            <input
              type="checkbox"
              id="checkbox4"
              @click="
                tank_filter.tank4 = !tank_filter.tank4;
                key++;
              "
            />
            <label for="checkbox4" class="label_txt">발효탱크#4</label>
          </div>
        </div>
        <div class="set_check_wrap">
          <div class="input_checkbox">
            <label
              for="checkbox5"
              class="chk_box"
              :class="ph_or_temp_filter.ph ? 'active' : ''"
            >
              <i class="fa fa-check"></i>
            </label>
            <input
              type="checkbox"
              id="checkbox5"
              @click="
                ph_or_temp_filter.ph = !ph_or_temp_filter.ph;
                key++;
              "
            />
            <label for="checkbox5" class="label_txt">PH</label>
          </div>
          <div class="input_checkbox">
            <label
              for="checkbox6"
              class="chk_box"
              :class="ph_or_temp_filter.temp ? 'active' : ''"
            >
              <i class="fa fa-check"></i>
            </label>
            <input
              type="checkbox"
              id="checkbox6"
              @click="
                ph_or_temp_filter.temp = !ph_or_temp_filter.temp;
                key++;
              "
            />
            <label for="checkbox6" class="label_txt">온도</label>
          </div>
        </div>
      </div>
      <div class="ph_graph">
        <h4 v-if="tank_logs.length > 0">PH 그래프</h4>
        <report-bar-chart
          v-if="tank_logs.length > 0"
          class="report_bar_chart"
          :data="chartData.data"
          :options="chartData.options"
          :height="$screen.width > 1280 ? 120 : 140"
          :key="key"
        ></report-bar-chart>
      </div>
      <div class="th_graph">
        <h4 v-if="tank_temp_logs.length > 0">온도 그래프</h4>
        <report-bar-chart
          v-if="tank_temp_logs.length > 0"
          class="report_bar_chart"
          :data="chartData2.data"
          :options="chartData2.options"
          :height="$screen.width > 1280 ? 120 : 140"
          :key="key + 1000"
        ></report-bar-chart>
      </div>
      <div class="tbl_layout">
        <div class="mes_tbl_wrap">
          <table class="mes_tbl">
            <thead>
              <tr>
                <th>일자</th>
                <th>탱크</th>
                <th>PH값</th>
                <th>온도</th>
              </tr>
            </thead>
            <tbody v-if="tank_logs.length > 0">
              <tr v-for="t in tank_log_display" :key="t.id">
                <td>{{ t.input_date }}</td>
                <td>
                  {{ findInfoFromId(plc_sensors, t.plc_sensor_id).name }}
                </td>
                <td>{{ t.value }}</td>
                <td>
                  {{
                    tank_temp_log_display.find(
                      z =>
                        z.input_date == t.input_date &&
                        Number(z.plc_sensor_id) + 4 == Number(t.plc_sensor_id),
                    ) != undefined
                      ? tank_temp_log_display.find(
                          z =>
                            z.input_date == t.input_date &&
                            Number(z.plc_sensor_id) + 4 ==
                              Number(t.plc_sensor_id),
                        ).value
                      : '-'
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import XLSX from 'xlsx';
import ModalMixin from '@/mixins/modal';
import SpinnerMixin from '@/mixins/spinner';
import { yyyymmdd } from '@/utils/func';
import ReportBarChart from '@/layouts/components/charts/ReportBarChart';
import FETCHMIXIN from '@/mixins/fetch';

export default {
  mixins: [ModalMixin, SpinnerMixin, FETCHMIXIN],
  components: {
    ReportBarChart,
  },
  data() {
    return {
      key: 0,
      screenWidth: this.screenWidth < 1280,
      modalData: null,
      isGraphModalOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      start_date: 'getStartDateFromDailyTankLogPage',
      end_date: 'getEndDateFromDailyTankLogPage',
      tank_logs_src: 'getTankLogFromDailyTankLogPage',
      tank_temp_logs_src: 'getTankTempLogFromDailyTankLogPage',
      plc_sensors: 'getPlcSensor',
      tank_filter: 'getTankFilterFromDailyTankLogPage',
      ph_or_temp_filter: 'getPhOrTempFilterFromDailyTankLogPage',
    }),
    tank_logs() {
      if (this.tank_logs_src.length < 1) {
        return [];
      }
      return this.lodash.clonedeep(this.tank_logs_src).filter(x => {
        if (!this.ph_or_temp_filter.ph) {
          return false;
        } else if (x.plc_sensor_id == 5 && this.tank_filter.tank1) {
          return true;
        } else if (x.plc_sensor_id == 6 && this.tank_filter.tank2) {
          return true;
        } else if (x.plc_sensor_id == 7 && this.tank_filter.tank3) {
          return true;
        } else if (x.plc_sensor_id == 8 && this.tank_filter.tank4) {
          return true;
        } else return false;
      });
    },
    tank_temp_logs() {
      if (this.tank_temp_logs_src.length < 1) {
        return [];
      }
      return this.lodash.clonedeep(this.tank_temp_logs_src).filter(x => {
        if (!this.ph_or_temp_filter.temp) {
          return false;
        } else if (x.plc_sensor_id == 1 && this.tank_filter.tank1) {
          return true;
        } else if (x.plc_sensor_id == 2 && this.tank_filter.tank2) {
          return true;
        } else if (x.plc_sensor_id == 3 && this.tank_filter.tank3) {
          return true;
        } else if (x.plc_sensor_id == 4 && this.tank_filter.tank4) {
          return true;
        } else return false;
      });
    },
    tank_log_display() {
      if (this.tank_logs.length < 1) return [];
      return this.lodash
        .clonedeep(this.tank_logs)
        .map(x => x.element)
        .reduce((a, b) => a.concat(b))
        .filter(
          x => this.start_date <= x.input_date && this.end_date >= x.input_date,
        )
        .sort(
          (a, b) =>
            new Date(a.input_date) - new Date(b.input_date) ||
            a.plc_sensor_id - b.plc_sensor_id,
        );
    },
    tank_temp_log_display() {
      if (this.tank_temp_logs.length < 1) return [];
      return this.lodash
        .clonedeep(this.tank_temp_logs)
        .map(x => x.element)
        .reduce((a, b) => a.concat(b))
        .filter(
          x => this.start_date <= x.input_date && this.end_date >= x.input_date,
        )
        .sort(
          (a, b) =>
            new Date(a.input_date) - new Date(b.input_date) ||
            a.plc_sensor_id - b.plc_sensor_id,
        );
    },
    chartData() {
      let tank_logs = this.lodash.clonedeep(this.tank_logs).map(x => {
        x.element = x.element
          .filter(
            x =>
              x.input_date <= this.end_date && x.input_date >= this.start_date,
          )
          .sort((a, b) => new Date(a.input_date) - new Date(b.input_date));
        x.plc_sensor_name = this.findInfoFromId(
          this.plc_sensors,
          x.plc_sensor_id,
        ).name;
        return x;
      });
      const colorArr = ['#004a89', '#6c9bd2', '#f98787', '#a0a0a0'];
      let data = {
        labels: tank_logs[0].element.map(x => x.input_date),
        datasets: tank_logs.map((x, index) => {
          return {
            label: x.plc_sensor_name + ' PH',
            data: x.element.map(x => x.value),
            categoryPercentage: 0.4,
            barPercentage: 0.9,
            borderColor: colorArr[index],
            pointBackgroundColor: colorArr[index],
            pointColor: colorArr[index],
            pointRadius: 4,
            displayColors: colorArr[index],
            lineTension: 0,
            yAxisID: 'y-axis-left',
            fill: false,
            type: 'line',
          };
        }),
      };

      return { data: data, options: this.getOption() };
    },
    chartData2() {
      let tank_temp_logs = this.lodash.clonedeep(this.tank_temp_logs).map(x => {
        x.element = x.element
          .filter(
            x =>
              x.input_date <= this.end_date && x.input_date >= this.start_date,
          )
          .sort((a, b) => new Date(a.input_date) - new Date(b.input_date));
        x.plc_sensor_name = this.findInfoFromId(
          this.plc_sensors,
          x.plc_sensor_id,
        ).name;
        return x;
      });
      const colorArr = ['#004a89', '#6c9bd2', '#f98787', '#a0a0a0'];
      let data = {
        labels: tank_temp_logs[0].element.map(x => x.input_date),
        datasets: tank_temp_logs.map((x, index) => {
          return {
            label: x.plc_sensor_name + ' 온도',
            data: x.element.map(x => x.value),
            categoryPercentage: 0.4,
            barPercentage: 0.9,
            borderColor: colorArr[index],
            pointBackgroundColor: colorArr[index],
            pointColor: colorArr[index],
            pointRadius: 4,
            displayColors: colorArr[index],
            lineTension: 0,
            yAxisID: 'y-axis-left',
            fill: false,
            type: 'line',
          };
        }),
      };

      return { data: data, options: this.getOption() };
    },
  },
  methods: {
    getOption() {
      let tooltipEnable = true;
      let fontSize = this.$screen.width > 1280 ? 14 : 10;
      let options = {
        legend: {
          responsive: true,
          position: 'bottom',
          align: 'middle',
          display: true,
          labels: {
            boxWidth: 12,
          },
        },
        // onClick: (mouse, evt) => {
        //   if (evt[0]) {
        //     let userId = this.groupBySession.find((x, y) => y == evt[0]._index)
        //       .user_id;
        //     const filteredAction = this.lodash
        //       .clonedeep(this.user_actions)
        //       .filter(x => x.user_id == userId);
        //     this.modalData = filteredAction;
        //     this.isGraphModalOpen = true;
        //   }
        // },

        scales: {
          xAxes: [
            {
              ticks: {
                fontSize,
              },
            },
          ],
          yAxes: [
            {
              stacked: false,
              id: 'y-axis-left',
              ticks: {
                // max: Math.max.apply(
                //   null,
                //   this.lodash
                //     .clonedeep(this.tank_logs)
                //     .map(x => Math.ceil(x.session_sum + x.session_avg)),
                // ),
                beginAtZero: true,
                fontSize,
                callback: value => {
                  return this.$makeComma(value);
                },
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            color: '#ffffff',
          },
        },
        elements: {
          point: {
            pointStyle: 'circle',
            pointColor: '#004a89',
            pointBackgroundColor: '#004a89',
          },
        },
        hover: {
          mode: 'nearest',
          intersect: true,
        },
        tooltips: {
          mode: 'index',
          intersect: false,
          custom: function(tooltip) {
            if (!tooltip) return;
            tooltip.displayColors = true;
          },
          enabled: tooltipEnable,
          // callbacks: {
          //   label: tooltipItems => {
          //     if (tooltipItems.datasetIndex === 0) {
          //       let multistringText = [];
          //       let sessionFilter = this.lodash
          //         .clonedeep(this.tank_logs)
          //         .filter(x => x.user_name == tooltipItems.label);
          //       multistringText.push(
          //         `[누적사용(분)] : ${sessionFilter.map(x => x.session_sum)}`,

          //         `[일 평균사용(분)] : ${sessionFilter.map(
          //           x => x.session_avg,
          //         )}`,
          //       );
          //       return multistringText;
          //     }
          //   },
          // },
        },
      };
      return options;
    },

    setStartDate(e) {
      if (e.target.value != '') {
        if (e.target.value > this.end_date) {
          this.openOneButtonModal(
            '조회기간 오류',
            `조회기간 최후날짜(${this.end_date}) 이후의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.start_date;
        } else {
          this.$store.commit('setStartDateToDailyTankLogPage', e.target.value);
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit(
          'setStartDateToDailyTankLogPage',
          yyyymmdd(new Date()),
        );
      }
    },
    setEndDate(e) {
      if (e.target.value != '') {
        if (e.target.value < this.start_date) {
          this.openOneButtonModal(
            '조회기간 오류',
            `조회기간 최초날짜(${this.start_date}) 이전의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.end_date;
        } else {
          this.$store.commit('setEndDateToDailyTankLogPage', e.target.value);
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit(
          'setEndDateToDailyTankLogPage',
          yyyymmdd(new Date()),
        );
      }
    },

    // exportExcel() {
    //   let userReport = [];
    //   let userReportList = [];

    //   const date = this.start_date + ' ~ ' + this.end_date;

    //   const merge = [
    //     { s: { r: 0, c: 1 }, e: { r: 0, c: 4 } },
    //     { s: { r: 1, c: 1 }, e: { r: 1, c: 4 } },
    //   ];

    //   let standardInfo = [
    //     ['기준시간', yyyy년MM월dd일HH시mm분ss초(new Date())],
    //     ['조회기간', date],
    //     [],
    //     [],
    //     [],
    //   ];

    //   userReport = userReport.concat(standardInfo);
    //   userReportList = userReportList.concat(standardInfo);

    //   userReport.push(['조회일자', '사용자명', '평균사용(분)', '누적사용(분)']);
    //   this.groupBySession.forEach(el => {
    //     userReport.push([
    //       date, //조회기간
    //       el.user_name, //아이디
    //       el.session_avg + '(분)', //평균사용
    //       el.session_sum + '(분)', //누적사용
    //     ]);
    //   });
    //   userReportList.push([
    //     '이름',
    //     '대 메뉴',
    //     '서브 메뉴',
    //     '이용 가능',
    //     '일자',
    //   ]);
    //   this.user_actions.forEach(el => {
    //     userReportList.push([
    //       this.getAccountFromUserId(el.user_id), //아이디
    //       this.getTopMenuNameFromMenuId(el.menu_group_id), //대메뉴
    //       this.getSubMenuNameFromMenuId(el.menu_group_id), //서브메뉴
    //       this.getActionNameFromTypeId(el.user_action_type_id), //이용가능
    //       el.create_time.substr(0, 16), //일자
    //     ]);
    //   });

    //   let wb = XLSX.utils.book_new();
    //   let dataUserReport = XLSX.utils.json_to_sheet(userReport, {
    //     skipHeader: true,
    //   });
    //   let dataUserReportList = XLSX.utils.json_to_sheet(userReportList, {
    //     skipHeader: true,
    //   });

    //   dataUserReport['!merges'] = dataUserReportList['!merges'] = merge;

    //   XLSX.utils.book_append_sheet(wb, dataUserReport, '사용자 이용현황');
    //   XLSX.utils.book_append_sheet(
    //     wb,
    //     dataUserReportList,
    //     '전체 사용자 이용현황 목록',
    //   );

    //   let title = '사용자 이용현황(' + date + ').xls';
    //   XLSX.writeFile(wb, title);
    // },
  },
  async created() {
    if (this.start_date == undefined) {
      var date = new Date();
      this.$store.commit('setEndDateToDailyTankLogPage', yyyymmdd(date));
      var date2 = new Date(date.setDate(date.getDate() - 7));
      this.$store.commit('setStartDateToDailyTankLogPage', yyyymmdd(date2));
    }
    await this.FETCH('FETCH_DAILY_TANK_LOG_DAY', '탱크');
  },
};
</script>

<style scoped></style>
