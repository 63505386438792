<template>
  <div
    class="article"
    :class="{ active: fullScreen == true }"
    :style="`background-color: ${bgBadUnsuk};`"
  >
    <div class="tbl_option">
      <div class="tank_set" :style="`background-color: ${BgUnsuk}`">
        <ul>
          <li :style="`color: ${TitleUnsuk};`">
            측정일시 :
            <span>
              {{
                plc_temperature_logs.sort().length == 0
                  ? ''
                  : yyyy년MM월dd일HH시mm분ss초2(
                      plc_temperature_logs[0].create_time,
                    )
              }}</span
            >
          </li>
        </ul>
      </div>
      <button
        class="btn_sub1 refresh_btn"
        :style="
          `background-color: ${btnAllColorUnsuk}; color: ${btnAllFontColorUnsuk};`
        "
        @click="refreshData"
      >
        재조회
      </button>
      <button
        class="btn_sub1"
        @click="fullScreen = !fullScreen"
        :style="
          `background-color: ${btnAllColorUnsuk}; color: ${btnAllFontColorUnsuk};`
        "
      >
        {{ fullScreen ? '닫기' : '전체화면' }}
      </button>
    </div>
    <div class="tank_wrap">
      <ul class="tank_list">
        <li
          v-for="item in plc_temperature_logs.sort(
            (a, b) => a.plc_sensor_id - b.plc_sensor_id,
          )"
          :key="item.id"
        >
          <h6 :style="`color: ${TankTitleColorUnsuk}`">
            {{ item.plc_sensor_name
            }}<span
              >설정온도 :
              {{
                defaultTemp.find(x => x.id == item.plc_sensor_id).min_value +
                  '~' +
                  defaultTemp.find(x => x.id == item.plc_sensor_id).max_value
              }}
              ℃</span
            >
          </h6>
          <div :style="`background-color: ${tankBgUnsuk}`">
            <small :style="`background-color: ${tankBgUnsuk}`"></small>
            <button
              :style="`color: ${tankFontUnsuk}`"
              :class="{ off: !item.tank }"
            >
              <!-- class = off 빨간색 -->
              {{ item.tank ? '가동 중' : '정지' }}
            </button>
            <ul>
              <li :style="`color: ${tankFontUnsuk}`">
                발효 시작일
                <span>{{
                  item.tank_time != null ? item.tank_time : ' - '
                }}</span>
              </li>
              <li
                :style="`color: ${tankFontUnsuk}`"
                :class="{
                  abnormal:
                    defaultTemp.find(x => x.id == item.plc_sensor_id)
                      .min_value > item.value ||
                    defaultTemp.find(x => x.id == item.plc_sensor_id)
                      .max_value < item.value,
                }"
              >
                발효액 온도 <span>{{ item.value }} ℃</span>
              </li>
              <li :style="`color: ${tankFontUnsuk}`">
                발효액 ph
                <span
                  >{{
                    plc_sensor_logs.find(
                      x => x.plc_sensor_id == item.plc_sensor_id + 4,
                    ).value
                  }}
                </span>
              </li>
              <li :style="`color: ${tankFontUnsuk}`">
                발효상태
                <span>{{
                  발효상태(
                    plc_sensor_logs.find(
                      x => x.plc_sensor_id == item.plc_sensor_id + 4,
                    ).value,
                    item.plc_sensor_id + 4,
                  )
                }}</span>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FETCH_MIXIN from '@/mixins/fetch';
import { yyyymmdd, yyyy년MM월dd일HH시mm분ss초 } from '@/utils/func';

export default {
  mixins: [FETCH_MIXIN],
  data() {
    return {
      fullScreen: false,
      str_time: null,
      timerCount: 10,
      timerMax: 10,
      interval: null,
      timeInterval: null,
      currentTimeInterval: null,

      articleColorUnsuk: localStorage.getItem('articleColorUnsuk'),
      bgBadUnsuk: localStorage.getItem('bgBadUnsuk'),
      btnAllColorUnsuk: localStorage.getItem('btnAllColorUnsuk'),
      btnAllFontColorUnsuk: localStorage.getItem('btnAllFontColorUnsuk'),
      TitleUnsuk: localStorage.getItem('TitleUnsuk'),
      BgUnsuk: localStorage.getItem('BgUnsuk'),
      TankTitleColorUnsuk: localStorage.getItem('TankTitleColorUnsuk'),
      tankBgUnsuk: localStorage.getItem('tankBgUnsuk'),
      tankFontUnsuk: localStorage.getItem('tankFontUnsuk'),
    };
  },
  computed: {
    ...mapGetters({
      plc_sensor_logs: 'getPlcSensorLog',
      plc_temperature_logs_src: 'getTemperatureSensorLog',
      plc_sensor_lists: 'getPlcSensor',
      tabIndex: 'getOpenTabIndexFromPlcMonitorPage',
      processing_lots: 'getProcessingLot',
      lot_tank: 'getLotTank',
    }),
    plc_temperature_logs() {
      if (this.plc_temperature_logs_src.length < 1) {
        return [];
      } else {
        return this.lodash.clonedeep(this.plc_temperature_logs_src).map(x => {
          x.tank = null;
          x.tank_time = null;
          if (x.plc_sensor_id == 1) {
            x.tank = this.tankFlag.tank1.tank_flag;
            x.tank_time = this.tankFlag.tank1.tank_flag
              ? this.tankFlag.tank1_time
              : null;
          } else if (x.plc_sensor_id == 2) {
            x.tank = this.tankFlag.tank2.tank_flag;
            x.tank_time = this.tankFlag.tank2.tank_flag
              ? this.tankFlag.tank2_time
              : null;
          } else if (x.plc_sensor_id == 3) {
            x.tank = this.tankFlag.tank3.tank_flag;
            x.tank_time = this.tankFlag.tank3.tank_flag
              ? this.tankFlag.tank3_time
              : null;
          } else if (x.plc_sensor_id == 4) {
            x.tank = this.tankFlag.tank4.tank_flag;
            x.tank_time = this.tankFlag.tank4.tank_flag
              ? this.tankFlag.tank4_time
              : null;
          }
          return x;
        });
      }
    },
    defaultTemp() {
      if (
        this.plc_sensor_lists != undefined &&
        this.plc_sensor_lists.length > 0
      ) {
        let clone = this.lodash
          .clonedeep(this.plc_sensor_lists)
          .filter(x => x.plc_sensor_type_id == 2)
          .sort((a, b) => a.id - b.id);
        return clone;
      } else {
        return [];
      }
    },
    tankFlag() {
      if (this.processing_lots.length < 1) {
        return { tank1: false, tank2: false, tank3: false, tank4: false };
      } else {
        const processing_lot_temp = this.lodash.clonedeep(this.processing_lots);
        const lot_tank_temp = this.lodash.clonedeep(this.lot_tank);
        console.log('processing_lot_temp', processing_lot_temp);
        console.log('lot_tank_temp', lot_tank_temp);
        if (lot_tank_temp.length < 1) {
          return { tank1: false, tank2: false, tank3: false, tank4: false };
        }
        let processing_lot_tank = processing_lot_temp.map(x => {
          return {
            tanks: lot_tank_temp.find(y => x.id == y.lot_id),
            tank_time:
              x.lot_resource.find(z => z.process_name.includes('발효')) !=
              undefined
                ? yyyymmdd(
                    new Date(
                      x.lot_resource.find(z =>
                        z.process_name.includes('발효'),
                      ).create_time,
                    ),
                  )
                : null,
          };
        });
        processing_lot_tank = processing_lot_tank.filter(
          x => x.tanks != undefined && x.tank_time != null,
        );
        console.log('processing_lot_tank', processing_lot_tank);

        const tank1s = processing_lot_tank.map(x => {
          return { time: x.tank_time, tank_flag: x.tanks.tank1 };
        });
        const tank2s = processing_lot_tank.map(x => {
          return { time: x.tank_time, tank_flag: x.tanks.tank2 };
        });
        const tank3s = processing_lot_tank.map(x => {
          return { time: x.tank_time, tank_flag: x.tanks.tank3 };
        });
        const tank4s = processing_lot_tank.map(x => {
          return { time: x.tank_time, tank_flag: x.tanks.tank4 };
        });

        // return { tank1s, tank2s, tank3s, tank4s };
        try {
          const returnValue = {
            tank1_time: this.lodash
              .clonedeep(tank1s)
              .sort((a, b) => new Date(a.time) - new Date(b.time))[0].time,
            tank2_time: this.lodash
              .clonedeep(tank2s)
              .sort((a, b) => new Date(a.time) - new Date(b.time))[0].time,
            tank3_time: this.lodash
              .clonedeep(tank3s)
              .sort((a, b) => new Date(a.time) - new Date(b.time))[0].time,
            tank4_time: this.lodash
              .clonedeep(tank4s)
              .sort((a, b) => new Date(a.time) - new Date(b.time))[0].time,
            tank1:
              tank1s.find(x => x.tank_flag == true) != undefined
                ? tank1s.find(x => x.tank_flag == true)
                : tank1s.find(x => x.tank_flag == false),
            tank2:
              tank2s.find(x => x.tank_flag == true) != undefined
                ? tank2s.find(x => x.tank_flag == true)
                : tank2s.find(x => x.tank_flag == false),
            tank3:
              tank3s.find(x => x.tank_flag == true) != undefined
                ? tank3s.find(x => x.tank_flag == true)
                : tank3s.find(x => x.tank_flag == false),
            tank4:
              tank4s.find(x => x.tank_flag == true) != undefined
                ? tank4s.find(x => x.tank_flag == true)
                : tank4s.find(x => x.tank_flag == false),
          };
          return returnValue;
        } catch (error) {
          return { tank1: false, tank2: false, tank3: false, tank4: false };
        }
      }
    },
  },
  methods: {
    발효상태(value, id) {
      const min_value = this.plc_sensor_lists.find(x => x.id == id).min_value;
      if (min_value >= value) {
        return '발효완료';
      } else return '발효중';
    },
    async refreshData() {
      await this.FETCH_NO_SPINNER('FETCH_PLC_SENSOR_LOG_NOW', '온습도현황');
      this.date = yyyy년MM월dd일HH시mm분ss초(new Date());
    },
    yyyy년MM월dd일HH시mm분ss초2(date2) {
      const date = new Date(date2);
      var MM = date.getMonth() + 1; // getMonth() is zero-based
      var dd = date.getDate();
      var hh = date.getHours();
      var mm = date.getMinutes();
      var ss = date.getSeconds();

      return [
        date.getFullYear(),
        '년 ' + (MM > 9 ? MM : '0' + MM),
        '월 ' + (dd > 9 ? dd : '0' + dd),
        '일 (' + (hh > 9 ? hh : '0' + hh),
        ':' + (mm > 9 ? mm : '0' + mm),
        ':' + (ss > 9 ? ss : '0' + ss),
        ')',
      ].join('');
    },
  },
  destroyed() {
    clearInterval(this.timeInterval);
    clearInterval(this.interval);
  },
  async created() {
    await this.FETCH('FETCH_LOT_TANK', '투입 탱크');
    if (this.plc_sensor_lists.length < 1) {
      await this.FETCH('FETCH_PLC_SENSOR', '센서 정보');
    }
    await this.FETCH_NO_SPINNER('FETCH_PLC_SENSOR_LOG_NOW', '온습도현황');
    await this.FETCH('FETCH_LOT_PROCESSING', '진행중 공정');

    this.interval = setInterval(async () => {
      this.timerCount = this.timerMax;

      if (this.tabIndex == 0) {
        await this.FETCH_NO_SPINNER('FETCH_PLC_SENSOR_LOG_NOW', '온습도현황');
        await this.FETCH_NO_SPINNER('FETCH_LOT_TANK', '투입 탱크');
        await this.FETCH_NO_SPINNER('FETCH_LOT_PROCESSING', '진행중 공정');
      }
      clearInterval(this.timeInterval);
      this.timeInterval = setInterval(() => {
        this.timerCount -= 1;
      }, 1000);
    }, this.timerMax * 1000);
    this.timeInterval = setInterval(() => {
      this.timerCount -= 1;
    }, 1000);
  },
};
</script>

<style lang="scss" scoped></style>
