<template>
  <div
    id="contents"
    :class="{
      status_board_unsuk: tabIndex == 0,
      plc_monitor: tabIndex == 0,
      tank_monitoring: tabIndex == 0,
      daily_tank_log: tabIndex == 1,
      default_setting: tabIndex == 2,
      color_setting: tabIndex == 3,
    }"
  >
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="aside" v-if="$screen.width < 1280">
        <aside-selectric
          :id="'tabletAsideSelectric'"
          :watch="tabIndex"
          :commit="'setOpenTabIndexToPlcMonitorPage'"
          :options="[
            { title: '발효탱크 모니터링', name: 'tank_monitoring' },
            { title: '일일 발효탱크 현황', name: 'daily_tank_log' },
            { title: '기준값 설정', name: 'default_plc_setting' },
            { title: '현황판 색 설정', name: 'color_setting' },
          ]"
        >
        </aside-selectric>
      </div>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li :class="{ active: tabIndex == 0 }">
            <a @click="SetOpenTabIndex(0)">발효탱크 모니터링</a>
          </li>
          <li :class="{ active: tabIndex == 1 }">
            <a @click="SetOpenTabIndex(1)">일일 발효탱크 현황</a>
          </li>
          <li :class="{ active: tabIndex == 2 }">
            <a @click="SetOpenTabIndex(2)">기준값 설정</a>
          </li>
          <li :class="{ active: tabIndex == 3 }">
            <a @click="SetOpenTabIndex(3)">현황판 색 설정</a>
          </li>
        </ul>
      </div>
      <plc-tank-monitoring-form v-if="tabIndex == 0"></plc-tank-monitoring-form>
      <daily-tank-log-form v-if="tabIndex == 1"></daily-tank-log-form>
      <default-plc-form v-if="tabIndex == 2"></default-plc-form>
      <color-picker-form v-if="tabIndex == 3"></color-picker-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import AsideSelectric from '@/layouts/components/AsideSelectric';
import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';
import PlcTankMonitoringForm from '@/views/forms/Custom/Monitor/8/PlcTankMonitoringForm';
import DefaultPlcForm from '@/views/forms/Custom/Monitor/8/DefaultPlcForm';
import ColorPickerForm from '@/views/forms/Custom/Monitor/8/ColorPickerForm';
import dailyTankLogForm from '@/views/forms/Custom/Monitor/8/dailyTankLogForm.vue';
import FETCH_MIXIN from '@/mixins/fetch';

export default {
  mixins: [ClosePageMixin, FavoriteMixin, FETCH_MIXIN],
  components: {
    AsideSelectric,
    PlcTankMonitoringForm,
    DefaultPlcForm,
    ColorPickerForm,
    dailyTankLogForm,
  },
  computed: {
    ...mapGetters({
      tabIndex: 'getOpenTabIndexFromPlcMonitorPage',
    }),
  },
  methods: {
    ...mapMutations({
      SetOpenTabIndex: 'setOpenTabIndexToPlcMonitorPage',
    }),
  },
  async created() {
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitPlcMonitorPage');
      this.$route.meta.menu_init = false;
    }
  },
};
</script>

<style lang="scss">
@import 'unsuk_monitoring';
</style>
