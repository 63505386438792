<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <h5>기준값 설정</h5>
        <span>조회기준시 : {{ searchTime }}</span>
      </div>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <thead>
            <tr>
              <th colspan="2">이름</th>
              <th>현재값</th>
              <th>최소값</th>
              <th>최대값</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in filteredPlcSensorLists" :key="item.id">
              <td>
                {{
                  item.plc_sensor_type_id == 4
                    ? '발효탱크-PH센서'
                    : `${item.name}-${
                        findInfoFromId(
                          plc_sensor_types,
                          item.plc_sensor_type_id,
                        ).name
                      }`
                }}
              </td>
              <td>
                {{
                  findInfoFromId(
                    plc_sensor_detail_types,
                    item.plc_sensor_detail_type_id,
                  ).name == 'PH값'
                    ? findInfoFromId(
                        plc_sensor_detail_types,
                        item.plc_sensor_detail_type_id,
                      ).name.replace('값', '')
                    : findInfoFromId(
                        plc_sensor_detail_types,
                        item.plc_sensor_detail_type_id,
                      ).name
                }}
              </td>
              <td>
                {{
                  plc_sensor_current_logs.length > 0 &&
                  plc_sensor_current_logs.find(
                    x => x.plc_sensor_id == item.id,
                  ) != undefined
                    ? plc_sensor_current_logs.find(
                        x => x.plc_sensor_id == item.id,
                      ).value
                    : ' - '
                }}
                {{ [1, 3].includes(item.plc_sensor_detail_type_id) ? `℃` : `` }}
              </td>
              <td
                :class="{
                  t_unit: [1, 3].includes(item.plc_sensor_detail_type_id),
                }"
              >
                <input
                  type="number"
                  placeholder="최소값 입력"
                  :value="item.min_value"
                  @input="minInput($event, item.id)"
                />
              </td>
              <td
                :class="{
                  t_unit: [1, 3].includes(item.plc_sensor_detail_type_id),
                }"
              >
                <input
                  type="number"
                  placeholder="최대값 입력"
                  v-model="item.max_value"
                  @input="maxInput($event, item.id)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="btn_wrap">
        <button class="btn_sub2" @click="submitData">저장</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { yyyy년MM월dd일HH시mm분ss초 } from '@/utils/func';
import FETCH_MIXIN from '@/mixins/fetch';

export default {
  mixins: [FETCH_MIXIN],
  computed: {
    ...mapGetters({
      plc_sensor_logs: 'getPlcSensorLog',
      plc_sensor_lists: 'getPlcSensor',
      plc_locations: 'getSensorLocations',
      plc_sensor_types: 'getSensorTypes',
      plc_sensor_detail_types: 'getSensorDetailTypes',
      plc_sensor_current_logs: 'getPlcSensorCurrentLog',
    }),
    searchTime() {
      let date = null;

      if (
        this.plc_sensor_current_logs != undefined &&
        this.plc_sensor_current_logs.length > 0
      ) {
        date = new Date(this.plc_sensor_current_logs[0].create_time);
      } else {
        date = new Date();
      }
      return yyyy년MM월dd일HH시mm분ss초(date);
    },
    filteredPlcSensorLists() {
      if (this.plc_sensor_lists == undefined) {
        return [];
      } else {
        const TorTH = this.lodash.clonedeep(
          this.plc_sensor_lists.filter(x =>
            ['T', 'PH'].includes(
              this.findInfoFromId(this.plc_sensor_types, x.plc_sensor_type_id)
                .detail,
            ),
          ),
        );
        const TempLogs = TorTH.filter(x =>
          [1, 3].includes(x.plc_sensor_detail_type_id),
        );
        const PhLogs = TorTH.filter(
          x => x.plc_sensor_type_id == 4 && ![6, 7, 8].includes(x.id),
        );
        return TempLogs.concat(PhLogs).sort((a, b) => a.id - b.id);
      }
    },
  },
  methods: {
    minInput(e, id) {
      this.plc_sensor_lists.find(x => x.id == id).min_value = e.target.value;
    },
    maxInput(e, id) {
      this.plc_sensor_lists.find(x => x.id == id).max_value = e.target.value;
    },
    async submitData() {
      let clone = this.lodash.clonedeep(this.filteredPlcSensorLists);
      const phMin = this.$makeNumber(
        clone.find(x => x.plc_sensor_type_id == 4).min_value,
      );

      const phMax = this.$makeNumber(
        clone.find(x => x.plc_sensor_type_id == 4).max_value,
      );

      clone.forEach(el => {
        el.min_value = this.$makeNumber(el.min_value);
        el.max_value = this.$makeNumber(el.max_value);
      });

      clone.push({
        id: 6,
        min_value: phMin,
        max_value: phMax,
      });
      clone.push({
        id: 7,
        min_value: phMin,
        max_value: phMax,
      });
      clone.push({
        id: 8,
        min_value: phMin,
        max_value: phMax,
      });

      let payload = {
        data_list: clone,
      };

      this.showSpinner();
      await this.$store
        .dispatch('UPDATE_FETCH_PLC_SENSOR_CURRENT_LOG_TO_PLC_SENSR', payload)
        .then(() => {
          this.openOneButtonModal('수정 성공', '성공적으로 수정하였습니다.');
          this.FETCH('FETCH_PLC_SENSOR', '센서 정보');
        })
        .catch(error => {
          console.log(error);
          this.openOneButtonModal(
            '저장 중 에러',
            '기준값 설정 저장 중 에러발생',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
  },

  async created() {
    if (this.plc_sensor_lists.length < 1) {
      await this.FETCH('FETCH_PLC_SENSOR', '센서 정보');
    }
    if (this.plc_locations.length < 1) {
      await this.FETCH('FETCH_PLC_SENSOR_LOCATION', '센서 위치');
    }
    if (this.plc_sensor_types.length < 1) {
      await this.FETCH('FETCH_PLC_SENSOR_TYPE', '센서 유형');
    }
    if (this.plc_sensor_detail_types.length < 1) {
      await this.FETCH('FETCH_PLC_SENSOR_DETAIL_TYPE', '센서 상세유형');
    }
    if (this.plc_sensor_current_logs.length < 1) {
      await this.FETCH(
        'FETCH_PLC_SENSOR_CURRENT_LOG_TO_PLC_SENSR',
        '센서현재값',
      );
    }
  },
};
</script>
