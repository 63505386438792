var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"article",class:{ active: _vm.fullScreen == true },style:(("background-color: " + _vm.bgBadUnsuk + ";"))},[_c('div',{staticClass:"tbl_option"},[_c('div',{staticClass:"tank_set",style:(("background-color: " + _vm.BgUnsuk))},[_c('ul',[_c('li',{style:(("color: " + _vm.TitleUnsuk + ";"))},[_vm._v(" 측정일시 : "),_c('span',[_vm._v(" "+_vm._s(_vm.plc_temperature_logs.sort().length == 0 ? '' : _vm.yyyy년MM월dd일HH시mm분ss초2( _vm.plc_temperature_logs[0].create_time )))])])])]),_c('button',{staticClass:"btn_sub1 refresh_btn",style:(("background-color: " + _vm.btnAllColorUnsuk + "; color: " + _vm.btnAllFontColorUnsuk + ";")),on:{"click":_vm.refreshData}},[_vm._v(" 재조회 ")]),_c('button',{staticClass:"btn_sub1",style:(("background-color: " + _vm.btnAllColorUnsuk + "; color: " + _vm.btnAllFontColorUnsuk + ";")),on:{"click":function($event){_vm.fullScreen = !_vm.fullScreen}}},[_vm._v(" "+_vm._s(_vm.fullScreen ? '닫기' : '전체화면')+" ")])]),_c('div',{staticClass:"tank_wrap"},[_c('ul',{staticClass:"tank_list"},_vm._l((_vm.plc_temperature_logs.sort(
          function (a, b) { return a.plc_sensor_id - b.plc_sensor_id; }
        )),function(item){return _c('li',{key:item.id},[_c('h6',{style:(("color: " + _vm.TankTitleColorUnsuk))},[_vm._v(" "+_vm._s(item.plc_sensor_name)),_c('span',[_vm._v("설정온도 : "+_vm._s(_vm.defaultTemp.find(function (x) { return x.id == item.plc_sensor_id; }).min_value + '~' + _vm.defaultTemp.find(function (x) { return x.id == item.plc_sensor_id; }).max_value)+" ℃")])]),_c('div',{style:(("background-color: " + _vm.tankBgUnsuk))},[_c('small',{style:(("background-color: " + _vm.tankBgUnsuk))}),_c('button',{class:{ off: !item.tank },style:(("color: " + _vm.tankFontUnsuk))},[_vm._v(" "+_vm._s(item.tank ? '가동 중' : '정지')+" ")]),_c('ul',[_c('li',{style:(("color: " + _vm.tankFontUnsuk))},[_vm._v(" 발효 시작일 "),_c('span',[_vm._v(_vm._s(item.tank_time != null ? item.tank_time : ' - '))])]),_c('li',{class:{
                abnormal:
                  _vm.defaultTemp.find(function (x) { return x.id == item.plc_sensor_id; })
                    .min_value > item.value ||
                  _vm.defaultTemp.find(function (x) { return x.id == item.plc_sensor_id; })
                    .max_value < item.value,
              },style:(("color: " + _vm.tankFontUnsuk))},[_vm._v(" 발효액 온도 "),_c('span',[_vm._v(_vm._s(item.value)+" ℃")])]),_c('li',{style:(("color: " + _vm.tankFontUnsuk))},[_vm._v(" 발효액 ph "),_c('span',[_vm._v(_vm._s(_vm.plc_sensor_logs.find( function (x) { return x.plc_sensor_id == item.plc_sensor_id + 4; } ).value)+" ")])]),_c('li',{style:(("color: " + _vm.tankFontUnsuk))},[_vm._v(" 발효상태 "),_c('span',[_vm._v(_vm._s(_vm.발효상태( _vm.plc_sensor_logs.find( function (x) { return x.plc_sensor_id == item.plc_sensor_id + 4; } ).value, item.plc_sensor_id + 4 )))])])])])])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }