var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"article"},[_c('div',{staticClass:"tbl_wrap"},[_c('div',{staticClass:"tbl_option"},[_c('div',{staticClass:"search_wrap"},[_c('span',{staticClass:"option_txt"},[_vm._v("조회기간")]),_c('div',{staticClass:"input_text"},[_c('input',{attrs:{"type":"date","placeholder":"YYYY-MM-DD"},domProps:{"value":_vm.start_date},on:{"change":function($event){_vm.setStartDate($event);
              _vm.key++;}}})]),_c('p',[_vm._v("~")]),_c('div',{staticClass:"input_text"},[_c('input',{attrs:{"type":"date","placeholder":"YYYY-MM-DD"},domProps:{"value":_vm.end_date},on:{"change":function($event){_vm.setEndDate($event);
              _vm.key++;}}})]),_c('div',{staticClass:"input_text"},[_c('input',{attrs:{"type":"text","disabled":""},domProps:{"value":("발효완료 PH값 : " + (_vm.plc_sensors.find(function (x) { return x.id == 5; })
                  ? _vm.plc_sensors.find(function (x) { return x.id == 5; }).min_value
                  : ' - ') + "이하")}})])]),_c('div',{staticClass:"tank_check_wrap"},[_c('div',{staticClass:"input_checkbox"},[_c('label',{staticClass:"chk_box",class:_vm.tank_filter.tank1 ? 'active' : '',attrs:{"for":"checkbox1"}},[_c('i',{staticClass:"fa fa-check"})]),_c('input',{attrs:{"type":"checkbox","id":"checkbox1"},on:{"click":function($event){_vm.tank_filter.tank1 = !_vm.tank_filter.tank1;
              _vm.key++;}}}),_c('label',{staticClass:"label_txt",attrs:{"for":"checkbox1"}},[_vm._v("발효탱크#1")])]),_c('div',{staticClass:"input_checkbox"},[_c('label',{staticClass:"chk_box",class:_vm.tank_filter.tank2 ? 'active' : '',attrs:{"for":"checkbox2"}},[_c('i',{staticClass:"fa fa-check"})]),_c('input',{attrs:{"type":"checkbox","id":"checkbox2"},on:{"click":function($event){_vm.tank_filter.tank2 = !_vm.tank_filter.tank2;
              _vm.key++;}}}),_c('label',{staticClass:"label_txt",attrs:{"for":"checkbox2"}},[_vm._v("발효탱크#2")])]),_c('div',{staticClass:"input_checkbox"},[_c('label',{staticClass:"chk_box",class:_vm.tank_filter.tank3 ? 'active' : '',attrs:{"for":"checkbox3"}},[_c('i',{staticClass:"fa fa-check"})]),_c('input',{attrs:{"type":"checkbox","id":"checkbox3"},on:{"click":function($event){_vm.tank_filter.tank3 = !_vm.tank_filter.tank3;
              _vm.key++;}}}),_c('label',{staticClass:"label_txt",attrs:{"for":"checkbox3"}},[_vm._v("발효탱크#3")])]),_c('div',{staticClass:"input_checkbox"},[_c('label',{staticClass:"chk_box",class:_vm.tank_filter.tank4 ? 'active' : '',attrs:{"for":"checkbox4"}},[_c('i',{staticClass:"fa fa-check"})]),_c('input',{attrs:{"type":"checkbox","id":"checkbox4"},on:{"click":function($event){_vm.tank_filter.tank4 = !_vm.tank_filter.tank4;
              _vm.key++;}}}),_c('label',{staticClass:"label_txt",attrs:{"for":"checkbox4"}},[_vm._v("발효탱크#4")])])]),_c('div',{staticClass:"set_check_wrap"},[_c('div',{staticClass:"input_checkbox"},[_c('label',{staticClass:"chk_box",class:_vm.ph_or_temp_filter.ph ? 'active' : '',attrs:{"for":"checkbox5"}},[_c('i',{staticClass:"fa fa-check"})]),_c('input',{attrs:{"type":"checkbox","id":"checkbox5"},on:{"click":function($event){_vm.ph_or_temp_filter.ph = !_vm.ph_or_temp_filter.ph;
              _vm.key++;}}}),_c('label',{staticClass:"label_txt",attrs:{"for":"checkbox5"}},[_vm._v("PH")])]),_c('div',{staticClass:"input_checkbox"},[_c('label',{staticClass:"chk_box",class:_vm.ph_or_temp_filter.temp ? 'active' : '',attrs:{"for":"checkbox6"}},[_c('i',{staticClass:"fa fa-check"})]),_c('input',{attrs:{"type":"checkbox","id":"checkbox6"},on:{"click":function($event){_vm.ph_or_temp_filter.temp = !_vm.ph_or_temp_filter.temp;
              _vm.key++;}}}),_c('label',{staticClass:"label_txt",attrs:{"for":"checkbox6"}},[_vm._v("온도")])])])]),_c('div',{staticClass:"ph_graph"},[(_vm.tank_logs.length > 0)?_c('h4',[_vm._v("PH 그래프")]):_vm._e(),(_vm.tank_logs.length > 0)?_c('report-bar-chart',{key:_vm.key,staticClass:"report_bar_chart",attrs:{"data":_vm.chartData.data,"options":_vm.chartData.options,"height":_vm.$screen.width > 1280 ? 120 : 140}}):_vm._e()],1),_c('div',{staticClass:"th_graph"},[(_vm.tank_temp_logs.length > 0)?_c('h4',[_vm._v("온도 그래프")]):_vm._e(),(_vm.tank_temp_logs.length > 0)?_c('report-bar-chart',{key:_vm.key + 1000,staticClass:"report_bar_chart",attrs:{"data":_vm.chartData2.data,"options":_vm.chartData2.options,"height":_vm.$screen.width > 1280 ? 120 : 140}}):_vm._e()],1),_c('div',{staticClass:"tbl_layout"},[_c('div',{staticClass:"mes_tbl_wrap"},[_c('table',{staticClass:"mes_tbl"},[_vm._m(0),(_vm.tank_logs.length > 0)?_c('tbody',_vm._l((_vm.tank_log_display),function(t){return _c('tr',{key:t.id},[_c('td',[_vm._v(_vm._s(t.input_date))]),_c('td',[_vm._v(" "+_vm._s(_vm.findInfoFromId(_vm.plc_sensors, t.plc_sensor_id).name)+" ")]),_c('td',[_vm._v(_vm._s(t.value))]),_c('td',[_vm._v(" "+_vm._s(_vm.tank_temp_log_display.find( function (z) { return z.input_date == t.input_date && Number(z.plc_sensor_id) + 4 == Number(t.plc_sensor_id); } ) != undefined ? _vm.tank_temp_log_display.find( function (z) { return z.input_date == t.input_date && Number(z.plc_sensor_id) + 4 == Number(t.plc_sensor_id); } ).value : '-')+" ")])])}),0):_vm._e()])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("일자")]),_c('th',[_vm._v("탱크")]),_c('th',[_vm._v("PH값")]),_c('th',[_vm._v("온도")])])])}]

export { render, staticRenderFns }