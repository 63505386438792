<template>
  <div class="article">
    <div class="head">
      <h5>현황판 색 설정</h5>
    </div>
    <div class="form">
      <!-- <form>
        <h5>온습도 모니터링</h5>
        <ul>
          <li>
            <div>
              <input
                id="thBgColorUnsuk"
                type="color"
                v-model="background_pick"
              />
            </div>
            <label for="thBgColorUnsuk">전체 배경</label>
          </li>
          <li>
            <div>
              <input id="thBdColorUnsuk" type="color" v-model="border_pick" />
            </div>
            <label for="thBdColorUnsuk">테이블 외부 테두리</label>
          </li>
          <li>
            <div>
              <input id="thBdiColorUnsuk" type="color" v-model="border_inner" />
            </div>
            <label for="thBdiColorUnsuk">테이블 내부 테두리</label>
          </li>
          <li>
            <div>
              <input
                id="thCrtColorUnsuk"
                type="color"
                v-model="background_temp"
              />
            </div>
            <label for="thCrtColorUnsuk">테이블 제목 배경(첫번째)</label>
          </li>
          <li>
            <div>
              <input id="thCrtFontUnsuk" type="color" v-model="color_temp" />
            </div>
            <label for="thCrtFontUnsuk"> 테이블 제목 글자(첫번째) </label>
          </li>
          <li>
            <div>
              <input
                id="thDivColorUnsuk"
                type="color"
                v-model="background_division"
              />
            </div>
            <label for="thDivColorUnsuk">테이블 제목 배경(두번째)</label>
          </li>
          <li>
            <div>
              <input
                id="thDivFontUnsuk"
                type="color"
                v-model="color_division"
              />
            </div>
            <label for="thDivFontUnsuk">테이블 제목 글자 (두번째)</label>
          </li>
        </ul>
        <ul>
          <li>
            <div>
              <input
                id="thTableColorUnsuk"
                type="color"
                v-model="background_table"
              />
            </div>
            <label for="thTableColorUnsuk">테이블 내용 배경 </label>
          </li>
          <li>
            <div>
              <input
                id="thDetailColorUnsuk"
                type="color"
                v-model="color_detail"
              />
            </div>
            <label for="thDetailColorUnsuk">테이블 내용 글자(구분)</label>
          </li>
          <li>
            <div>
              <input
                id="thDetailFontUnsuk"
                type="color"
                v-model="color_detail_temp"
              />
            </div>
            <label for="thDetailFontUnsuk">테이블 내용 글자(온도, 습도)</label>
          </li>
          <li>
            <div>
              <input
                id="thBtnColorUnsuk"
                type="color"
                v-model="background_button"
              />
            </div>
            <label for="thBtnColorUnsuk">전체화면 버튼 배경</label>
          </li>
          <li>
            <div>
              <input id="thBtnFontUnsuk" type="color" v-model="color_button" />
            </div>
            <label for="thBtnFontUnsuk">전체화면 버튼 글자</label>
          </li>
        </ul>
        <div class="btn_wrap">
          <button
            id="tempColorUnsuk"
            class="btn_sub2"
            @click.prevent="showModal($event)"
          >
            초기화
          </button>
          <button class="btn_sub2" @click.prevent="submitFormTH">저장</button>
        </div>
      </form> -->
      <form>
        <h5>발효탱크 모니터링</h5>
        <ul>
          <li>
            <div>
              <input
                id="metalBgColorUnsuk"
                type="color"
                v-model="background_bad"
              />
            </div>
            <label for="metalBgColorUnsuk">전체 배경</label>
          </li>
          <li>
            <div>
              <input
                id="TankBtnColorUnsuk"
                type="color"
                v-model="background_btn"
              />
            </div>
            <label for="TankBtnColorUnsuk">전체화면 버튼 배경</label>
          </li>
          <li>
            <div>
              <input id="TankBtnFontUnsuk" type="color" v-model="color_btn" />
            </div>
            <label for="TankBtnFontUnsuk">전체화면 버튼 글자</label>
          </li>
          <li>
            <div>
              <input id="titleBgUnsuk" type="color" v-model="bg_title" />
            </div>
            <label for="titleBgUnsuk">측정일시/설정온도 배경</label>
          </li>
          <li>
            <div>
              <input id="titleFontUnsuk" type="color" v-model="color_title" />
            </div>
            <label for="titleFontUnsuk">측정일시/설정온도 글자</label>
          </li>
          <li>
            <div>
              <input
                id="tankTitleFontUnsuk"
                type="color"
                v-model="color_title_tank"
              />
            </div>
            <label for="tankTitleFontUnsuk">발효탱크 제목 글자</label>
          </li>
          <li>
            <div>
              <input id="tankBgUnsuk" type="color" v-model="background_tank" />
            </div>
            <label for="tankBgUnsuk">발효탱크 배경</label>
          </li>
          <li>
            <div>
              <input id="tankFontUnsuk" type="color" v-model="color_tank" />
            </div>
            <label for="tankFontnsuk">발효탱크 글자</label>
          </li>
        </ul>
        <div class="btn_wrap">
          <button
            id="tankColorUnsuk"
            class="btn_sub2"
            @click.prevent="showModal($event)"
          >
            초기화
          </button>
          <button class="btn_sub2" @click.prevent="submitFormTank">
            저장
          </button>
        </div>
      </form>
    </div>
    <two-button-modal
      :modal_title="my_modal_title"
      :modal_content="my_modal_content"
      :isModalOpen="my_isModalOpen"
      @onclose="CloseModal"
      @OnYesClick="removeTH()"
    >
    </two-button-modal>
  </div>
</template>

<script>
import ModalMixin from '@/mixins/modal';
import TwoButtonModal from '@/layouts/components/TwoButtonModal';
export default {
  mixins: [ModalMixin],
  components: {
    TwoButtonModal,
  },
  data() {
    return {
      //실시간 온습도 현황 Color
      // background_pick: '#1e2242',
      // border_pick: '#1e2242',
      // border_inner: '#7f84aa',
      // background_temp: '#2f3664',
      // color_temp: '#ffffff',
      // background_division: '#252b54',
      // color_division: '#81c4ff',
      // background_table: '#3b427a',
      // color_detail: '#ffffff',
      // color_detail_temp: '#f98787',
      // background_button: '#3b427a',
      // color_button: '#ffffff',

      //발효탱크 현황 Color
      background_bad: '#1e2242',
      background_btn: '#3b427a',
      color_btn: '#ffffff',
      bg_title: '#252b54',
      color_title: '#ffffff',
      color_title_tank: '#81c4ff',
      background_tank: '#3b427a',
      color_tank: '#ffffff',

      //reset modal
      my_modal_title: '',
      my_modal_content: '',
      my_modal_index: -1,
      my_isModalOpen: false,
      tempColorUnsuk: false,
      tankColorUnsuk: false,
    };
  },
  mounted() {
    // this.background_pick = localStorage.getItem('articleColorUnsuk');
    // this.border_pick = localStorage.getItem('mesborTblUnsuk');
    // this.border_inner = localStorage.getItem('mesboriTblUnsuk');
    // this.background_temp = localStorage.getItem('tempbgColorUnsuk');
    // this.color_temp = localStorage.getItem('tempFontUnsuk');
    // this.background_division = localStorage.getItem('divColorUnsuk');
    // this.color_division = localStorage.getItem('divFontUnsuk');
    // this.background_table = localStorage.getItem('tableColorUnsuk');
    // this.color_detail = localStorage.getItem('detailFontUnsuk');
    // this.color_detail_temp = localStorage.getItem('detailTempFontUnsuk');
    // this.background_button = localStorage.getItem('btnColorUnsuk');
    // this.color_button = localStorage.getItem('btnFontColorUnsuk');

    this.background_bad = localStorage.getItem('bgBadUnsuk');
    this.background_btn = localStorage.getItem('btnAllColorUnsuk');
    this.color_btn = localStorage.getItem('btnAllFontColorUnsuk');
    this.color_title = localStorage.getItem('TitleUnsuk');
    this.bg_title = localStorage.getItem('BgUnsuk');
    this.color_tank_title = localStorage.getItem('TankTitleColorUnsuk');
    this.background_tank = localStorage.getItem('tankBgUnsuk');
    this.color_tank = localStorage.getItem('tankFontUnsuk');
  },

  methods: {
    showModal() {
      // if (e.target.id == 'tempColorUnsuk') {
      //   this.tempColorUnsuk = true;
      // } else {
      //   this.tankColorUnsuk = true;
      // }
      this.tankColorUnsuk = true;

      this.my_modal_title = '알림';
      this.my_modal_content = '정말로 초기화하시겠습니까?';
      this.my_isModalOpen = true;
    },
    CloseModal() {
      this.my_modal_index = -1;
      this.my_modal_title = '';
      this.my_modal_content = '';
      this.my_isModalOpen = false;
    },
    // submitFormTH() {
    //   localStorage.setItem('articleColorUnsuk', this.background_pick);
    //   localStorage.setItem('mesborTblUnsuk', this.border_pick);
    //   localStorage.setItem('mesboriTblUnsuk', this.border_inner);
    //   localStorage.setItem('tempbgColorUnsuk', this.background_temp);
    //   localStorage.setItem('tempFontUnsuk', this.color_temp);
    //   localStorage.setItem('divColorUnsuk', this.background_division);
    //   localStorage.setItem('divFontUnsuk', this.color_division);
    //   localStorage.setItem('tableColorUnsuk', this.background_table);
    //   localStorage.setItem('detailFontUnsuk', this.color_detail);
    //   localStorage.setItem('detailTempFontUnsuk', this.color_detail_temp);
    //   localStorage.setItem('btnColorUnsuk', this.background_button);
    //   localStorage.setItem('btnFontColorUnsuk', this.color_button);

    //   this.openOneButtonModal('저장 성공', '성공적으로 저장하였습니다.');
    // },
    removeTH() {
      if (this.tempColorUnsuk == true) {
        localStorage.setItem('articleColorUnsuk', '#1e2242'); //배경
        localStorage.setItem('mesborTblUnsuk', '#1e2242'); //테두리외부
        localStorage.setItem('mesboriTblUnsuk', '#7f84aa'); //테두리내부
        localStorage.setItem('tempbgColorUnsuk', '#2f3664'); //온습도현황 칼라
        localStorage.setItem('tempFontUnsuk', '#ffffff'); //온습도현황 폰트
        localStorage.setItem('divColorUnsuk', '#252b54'); //구분 온습도 배경
        localStorage.setItem('divFontUnsuk', '#81c4ff'); //구분 온습도 폰트
        localStorage.setItem('tableColorUnsuk', '#3b427a'); //테이블 칼라
        localStorage.setItem('detailFontUnsuk', '#ffffff'); //온습도폰트
        localStorage.setItem('detailTempFontUnsuk', '#f98787'); //구분폰트
        localStorage.setItem('btnColorUnsuk', '#3b427a'); //버튼색
        localStorage.setItem('btnFontColorUnsuk', '#ffffff'); // 버튼폰트

        this.background_pick = '#1e2242';
        this.border_pick = '#1e2242';
        this.border_inner = '#7f84aa';
        this.background_temp = '#2f3664';
        this.color_temp = '#ffffff';
        this.background_division = '#252b54';
        this.color_division = '#81c4ff';
        this.background_table = '#3b427a';
        this.color_detail = '#ffffff';
        this.color_detail_temp = '#f98787';
        this.background_button = '#3b427a';
        this.color_button = '#ffffff';
      } else if (this.tankColorUnsuk == true) {
        // 금속검출기 초기화
        this.removeTank();
      }
      this.tempColorUnsuk = false;
      this.tankColorUnsuk = false;
      this.CloseModal();
    },
    submitFormTank() {
      localStorage.setItem('bgBadUnsuk', this.background_bad);
      localStorage.setItem('btnAllColorUnsuk', this.background_btn);
      localStorage.setItem('btnAllFontColorUnsuk', this.color_btn);
      localStorage.setItem('TitleUnsuk', this.color_title);
      localStorage.setItem('BgUnsuk', this.bg_title);
      localStorage.setItem('TankTitleColorUnsuk', this.color_tank_title);
      localStorage.setItem('tankBgUnsuk', this.background_tank);
      localStorage.setItem('tankFontUnsuk', this.color_tank);

      this.openOneButtonModal('저장 성공', '성공적으로 저장하였습니다.');
    },
    removeTank() {
      localStorage.setItem('bgBadUnsuk', '#1e2242'); //금속검출기 배경
      localStorage.setItem('btnAllColorUnsuk', '#3b427a'); //버튼색상
      localStorage.setItem('btnAllFontColorUnsuk', '#ffffff'); //버튼폰트
      localStorage.setItem('TitleUnsuk', '#ffffff'); //불량검출현황 폰트
      localStorage.setItem('BgUnsuk', '#252b54'); //불량검출현황 폰트
      localStorage.setItem('TankTitleColorUnsuk', this.color_tank_title);
      localStorage.setItem('tankBgUnsuk', '#3b427a'); //카드 배경색상
      localStorage.setItem('tankFontUnsuk', '#ffffff'); //진행 폰트

      this.background_bad = '#1e2242';
      this.background_btn = '#3b427a';
      this.color_btn = '#ffffff';
      this.color_title = '#ffffff';
      this.bg_title = '#252b54';
      this.color_tank_title = '#81c4ff';
      this.background_tank = '#3b427a';
      this.color_tank = '#ffffff';
    },
  },
  async created() {
    // if (localStorage.getItem('articleColorUnsuk') == undefined) {
    //   localStorage.setItem('articleColorUnsuk', this.background_pick);
    // }
    // if (localStorage.getItem('mesborTblUnsuk') == undefined) {
    //   localStorage.setItem('mesborTblUnsuk', this.border_pick);
    // }
    // if (localStorage.getItem('mesboriTblUnsuk') == undefined) {
    //   localStorage.setItem('mesboriTblUnsuk', this.border_inner);
    // }
    // if (localStorage.getItem('tempbgColorUnsuk') == undefined) {
    //   localStorage.setItem('tempbgColorUnsuk', this.background_temp);
    // }
    // if (localStorage.getItem('tempFontUnsuk') == undefined) {
    //   localStorage.setItem('tempFontUnsuk', this.color_temp);
    // }
    // if (localStorage.getItem('divColorUnsuk') == undefined) {
    //   localStorage.setItem('divColorUnsuk', this.background_division);
    // }
    // if (localStorage.getItem('divFontUnsuk') == undefined) {
    //   localStorage.setItem('divFontUnsuk', this.color_division);
    // }
    // if (localStorage.getItem('tableColorUnsuk') == undefined) {
    //   localStorage.setItem('tableColorUnsuk', this.background_table);
    // }
    // if (localStorage.getItem('detailFontUnsuk') == undefined) {
    //   localStorage.setItem('detailFontUnsuk', this.color_detail);
    // }
    // if (localStorage.getItem('detailTempFontUnsuk') == undefined) {
    //   localStorage.setItem('detailTempFontUnsuk', this.color_detail_temp);
    // }
    // if (localStorage.getItem('btnColorUnsuk') == undefined) {
    //   localStorage.setItem('btnColorUnsuk', this.background_button);
    // }
    // if (localStorage.getItem('btnFontColorUnsuk') == undefined) {
    //   localStorage.setItem('btnFontColorUnsuk', this.color_button);
    // }

    if (localStorage.getItem('bgBadUnsuk') == undefined) {
      localStorage.setItem('bgBadUnsuk', this.background_bad);
    }
    if (localStorage.getItem('btnAllColorUnsuk') == undefined) {
      localStorage.setItem('btnAllColorUnsuk', this.background_btn);
    }
    if (localStorage.getItem('btnAllFontColorUnsuk') == undefined) {
      localStorage.setItem('btnAllFontColorUnsuk', this.color_btn);
    }
    if (localStorage.getItem('TitleUnsuk') == undefined) {
      localStorage.setItem('TitleUnsuk', this.color_title);
    }
    if (localStorage.getItem('BgUnsuk') == undefined) {
      localStorage.setItem('BgUnsuk', this.bg_title);
    }
    if (localStorage.getItem('TankTitleColorUnsuk') == undefined) {
      localStorage.setItem('TankTitleColorUnsuk', this.color_tank_title);
    }
    if (localStorage.getItem('tankBgUnsuk') == undefined) {
      localStorage.setItem('tankBgUnsuk', this.background_tank);
    }
    if (localStorage.getItem('tankFontUnsuk') == undefined) {
      localStorage.setItem('tankFontUnsuk', this.color_tank);
    }
  },
};
</script>

<style lang="scss" scoped></style>
